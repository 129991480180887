import React from 'react';
import profilePic from './profile-pic.png';
import { Link } from 'gatsby';
import './Bio.css';
import {Card, ListGroup, Accordion} from 'react-bootstrap';

class Bio extends React.Component {
  render() {
    return (
      <div className="bio">
        <div title="Sweeney Ridge" className="avatar">
          <Link to={'/'}>
            <img src={profilePic} alt={`Sweeney Ridge`} />
          </Link>
        </div>
        {/* <h4>Sweeney Ridge</h4> */}
        <p className="bio-slogan">
        🍻Beer, 🥖Delicacies🍱, ☕️Coffee & 🍑Provisions
        </p>
      </div>
    );
  }
}

export default Bio;
