import React from 'react';
import { Icon } from 'antd';

class Footer extends React.Component {
  render() {
    return (
      <footer>
        © Sweeney Ridge 
      </footer>
    );
  }
}

export default Footer;
